import type { AxiosResponse } from "axios";
import ReferenceData from "../../../constants/ReferenceData";
import { executeGet } from "../../../utils/appUtils";

interface RegistrationSummaryResponse {
    contributionRateList: string[];
    firstName: string;
    investmentOptions: {
        allocationPercentage: string;
        groupingCode: string;
        name: string;
    }[];
    lastName: string;
    planName: string;
}

const registrationSummary = (): Promise<AxiosResponse<RegistrationSummaryResponse>> => {
    return executeGet(ReferenceData.REGISTRATION_SUMMARY, "registrationSummaryFault");
};

export default registrationSummary;
