import React, {
    ChangeEvent,
    ClipboardEvent,
    KeyboardEvent,
    MouseEvent,
    useCallback,
    useEffect,
    useRef
} from "react";
import { ACTIVATION_CODE_DELIVERY_OPTIONS } from "../../../routes";
import { Link, useLocation } from "react-router-dom";
import { useTranslations } from "../../../queries";
import verifyCode from "../../../services/mfa/verifyCode";
import { sentryVerifyCodeSignIn } from "../../../../utils/sentryMetricsLogging";
import MFAEvents, { REMEMBER_DEVICE_AMPLITUDE_PAYLOAD } from "../MFAEvents";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { Controller, useForm } from "react-hook-form";
import verifyCodeByIndIdDbName from "../../../services/mfa/verifyCodeByIndIdDbName";
import eventBus from "../../../../utils/setEventBus";
import useRedirect from "../../../hooks/useRedirect";
import { getCookie } from "core-ui/client/react/core/actions/shared/sharedActions";

interface VerificationCodeEntryTranslations {
    "0000": string;
    codeLengthErrorMessage: string;
    footer: {
        customerSupport: {
            preLoginSection: {
                phoneNumber: string;
            };
        };
    };
    mfaCodeLength: number;
    mfaCodeSent: string;
    mfaHeader: string;
    mfaLabel: string;
    mfaRememberDevice: string;
    mfaResendCode: string;
    mfaSignIn: string;
}

interface VerificationCodeEntryProps {
    legacyRedirect?: (data: { state: string }, key: string, flowName: string) => void;
    linkingContext?: string;
}

interface VerificationCodeEntryFields {
    code: string[];
    rememberDevice: boolean;
}

const FLOW_NAME = "mfa";
const STATE_MAP_KEY = "ALL";
const PHONE = "{{phone}}";

const VerificationCodeEntry = ({ legacyRedirect, linkingContext }: VerificationCodeEntryProps) => {
    const {
        mfaHeader,
        mfaCodeSent,
        mfaLabel,
        mfaRememberDevice,
        mfaResendCode,
        mfaSignIn,
        mfaCodeLength,
        "0000": errorMessage,
        codeLengthErrorMessage,
        footer
    } = useTranslations<VerificationCodeEntryTranslations>();

    const servicePhoneNumber = footer.customerSupport.preLoginSection.phoneNumber;

    const updatedErrorMessage = errorMessage.replace(PHONE, servicePhoneNumber ?? "");
    const redirect = useRedirect();
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid }
    } = useForm<VerificationCodeEntryFields>({
        defaultValues: {
            code: new Array(mfaCodeLength).fill(""),
            rememberDevice: false
        },
        mode: "all"
    });

    const { state } = useLocation();
    const deepLinkName = state ? state.deepLinkName : "";
    const FIELDS = Array.from({ length: mfaCodeLength }, (_, index) => ({
        key: index
    }));

    const isOauthLogin = String(getCookie("isOauthLogin")) === "true";

    useEffect(() => {
        // focus on the first input field on component mount
        inputRefs.current[0].focus();
    }, []);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const index = Number(event.currentTarget.dataset.key);
            if (/^[0-9]$/.test(event.target.value.slice(-1))) {
                if (index < mfaCodeLength - 1) {
                    // jump to the next input if valid entry and not the last input
                    inputRefs.current[index + 1].focus();
                }
                return event.target.value.slice(-1);
            }
            return "";
        },
        [mfaCodeLength]
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            const index = Number(event.currentTarget.dataset.key);
            if (index > 0 && (event.key === "ArrowLeft" || (event.key === "Backspace" && !value))) {
                // jump to previous input if backspace is pressed and the input is empty or if the left arrow key is pressed
                event.preventDefault();
                inputRefs.current[index - 1].focus();
            }
            if (index < mfaCodeLength - 1 && event.key === "ArrowRight") {
                // jump to the next input if the right arrow key is pressed
                inputRefs.current[index + 1].focus();
            }
        },
        [mfaCodeLength]
    );

    const handlePaste = useCallback(
        (event: ClipboardEvent<HTMLInputElement>) => {
            event.preventDefault();
            const startIndex = Number(event.currentTarget.dataset.key);
            const paste = event.clipboardData.getData("text/plain");
            paste.split("").forEach((digit, index) => {
                if (startIndex + index < mfaCodeLength && /[0-9]/.test(digit)) {
                    setValue(`code.${startIndex + index}`, digit);
                    if (startIndex + index < mfaCodeLength - 1) {
                        inputRefs.current[startIndex + index + 1].focus();
                    }
                }
            });
        },
        [mfaCodeLength, setValue]
    );

    const executeVerifyCode = useCallback(
        async (formData: VerificationCodeEntryFields) => {
            const { code } = formData;
            if (linkingContext) {
                const payload = {
                    indIdDbName: linkingContext,
                    verificationCode: code.join("")
                };
                return verifyCodeByIndIdDbName(payload);
            } else {
                const { rememberDevice } = formData;
                const payload = {
                    rememberDevice,
                    verificationCode: code.join(""),
                    flowName: FLOW_NAME
                };
                return verifyCode(payload);
            }
        },
        [linkingContext]
    );

    const handleSignIn = useCallback(
        async (formData: VerificationCodeEntryFields) => {
            // tracks when the Proceed button is clicked on the MFA screen
            localStorage.removeItem(PerformanceTrackingKeys.PT_MFA_CLICKED);
            localStorage.setItem(
                PerformanceTrackingKeys.PT_MFA_CLICKED,
                String(new Date().getTime())
            );

            // capture the timestamp once the code has been validated
            sentryVerifyCodeSignIn();

            try {
                const { data } = await executeVerifyCode(formData);

                if (data) {
                    // TODO: May need to adjust where group id is set for other deep links
                    const groupId = data && data["primaryPlan"] ? data["primaryPlan"]["gaId"] : "";
                    const event = new CustomEvent("decoupledDeepLink", {
                        detail: { groupId, deepLinkName }
                    });

                    window.dispatchEvent(event);

                    if (legacyRedirect) {
                        legacyRedirect(data, STATE_MAP_KEY, FLOW_NAME);
                    } else {
                        redirect(data);
                    }
                }
            } catch (error) {
                control.setError("root", { message: updatedErrorMessage });
            }
        },
        [control, executeVerifyCode, legacyRedirect, updatedErrorMessage, deepLinkName, redirect]
    );

    const handleInvalid = useCallback(() => {
        control.setError("root", {
            message: StringUtil.supplant(codeLengthErrorMessage, { mfaCodeLength })
        });
    }, [control, codeLengthErrorMessage, mfaCodeLength]);

    const dispatchAmplitudeEvent = useCallback((event: MouseEvent<HTMLElement>) => {
        const { selection } = event.currentTarget.dataset;
        const payload = event.currentTarget.dataset.payload || event.currentTarget.textContent;

        // This if else statements are not ideal. When we move away from GA tags, we should remove this statements.
        if (payload === "Sign in") {
            eventBus.dispatch("MFAEvent.sign_in_button_clicked_event", event.target, payload);
        } else if (payload === "Remember device") {
            eventBus.dispatch(
                "MFAEvent.remember_device_checkbox_clicked_event",
                event.target,
                payload
            );
        } else if (payload === "Resend code") {
            eventBus.dispatch(
                "MFAEvent.MFA_didn't_receive_the_code_link_clicked",
                event.target,
                payload
            );
        }
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: String(selection),
            payload: {
                payload
            }
        });
    }, []);

    return (
        <form
            className="mfa-container"
            data-testid="verification-code-entry"
            autoComplete="off"
            onSubmit={handleSubmit(handleSignIn, handleInvalid)}
        >
            {isSubmitting && <div className="loader"></div>}
            <h2>{mfaHeader}</h2>
            <div className="description">{mfaCodeSent}</div>
            <div className="code-entry-container">
                <label>{mfaLabel}</label>
                <div className="input-container">
                    {FIELDS.map(({ key }) => (
                        <Controller
                            key={key}
                            name={`code.${key}`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    aria-label={mfaLabel}
                                    className="digit-input digit-font"
                                    data-testid={`input-${key}`}
                                    data-key={key}
                                    onChange={(event) => field.onChange(handleChange(event))}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    ref={(el: HTMLInputElement) => (inputRefs.current[key] = el)}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    min="0"
                                />
                            )}
                        />
                    ))}
                </div>
                {errors?.root?.message && (
                    <div className="error-block" role="alert" aria-live="polite">
                        {errors.root.message}
                    </div>
                )}
                {!isOauthLogin && (
                    <label className="checkbox-label">
                        <input
                            data-selection={MFAEvents.CLICK}
                            data-payload={REMEMBER_DEVICE_AMPLITUDE_PAYLOAD}
                            onClick={dispatchAmplitudeEvent}
                            type="checkbox"
                            {...register("rememberDevice")}
                        />
                        <span>{mfaRememberDevice}</span>
                    </label>
                )}
            </div>
            <div className="button-container">
                <Link
                    to={ACTIVATION_CODE_DELIVERY_OPTIONS}
                    data-selection={MFAEvents.LINK}
                    onClick={dispatchAmplitudeEvent}
                    state={state?.formData}
                >
                    {mfaResendCode}
                </Link>
                <button
                    className="btn btn-primary"
                    type="submit"
                    data-selection={MFAEvents.CTA_BUTTON}
                    onClick={dispatchAmplitudeEvent}
                    disabled={!isValid}
                >
                    {mfaSignIn}
                </button>
            </div>
        </form>
    );
};

export default VerificationCodeEntry;
