import React, { useCallback, useState } from "react";
import { useTranslations } from "../../../queries";
import useRegistrationSummary from "../../../queries/useRegistrationSummary";
import EDeliveryInlineViewContainer from "core-ui/client/react/core/components/eDelivery/EDeliveryInlineViewContainer";
import useParticipantConsent from "../../../queries/useParticipantConsent";
import registrationSummaryDestination from "../../../services/register/registrationSummaryDestination";
import useRedirect from "../../../hooks/useRedirect";
import useUpdateParticipantConsent from "../../../mutations/useUpdateParticipantConsent";
import useGetBeneficiaries from "../../../queries/useGetBeneficiaries";
import useGroupTxnAccess from "../../../queries/useGroupTxnAccess";
import usePrimaryPlan from "../../../queries/usePrimaryPlan";
import BeneficiaryTable from "./BeneficiaryTable";

interface RegistrationSummaryTranslations {
    "0000": string;
    app: {
        beneficiaryTable: {
            headings: {
                allocation: string;
                contingent: string;
                primary: string;
                type: string;
            };
        };
    };
    button: {
        agreeContinue: string;
        print: string;
    };
    summary: {
        beneficiaries: {
            title: string;
        };
        details: {
            contributionRate: string;
            investmentOptions: string;
            plan: string;
        };
        headline: string;
        pageTitle: string;
    };
}

interface GroupedInvestmentOptions {
    [key: string]: {
        allocationPercentage: string;
        groupingCode: string;
        name: string;
    }[];
}

type GroupedInvestmentOptionsType = GroupedInvestmentOptions | {} | undefined;

interface Beneficiary {
    beneTypeCode: string;
    designationCode: "P" | "C";
    firstName: string;
    id: string;
    lastName: string;
    percent: number;
}

const BENEFICIARIES_DEFAULT: {
    contingent: Beneficiary[];
    primary: Beneficiary[];
} = { primary: [], contingent: [] };

const RegistrationSummary = () => {
    const {
        app,
        button,
        summary,
        "0000": errorMessage
    } = useTranslations<RegistrationSummaryTranslations>();

    const redirect = useRedirect();

    const { data: primaryPlanData, isLoading: primaryPlanLoading } = usePrimaryPlan();

    const { data: summaryData, isLoading: summaryLoading } = useRegistrationSummary();

    const { data: eDeliveryData } = useParticipantConsent(primaryPlanData?.gaId);

    const { data: txnAccessData } = useGroupTxnAccess(
        primaryPlanData?.gaId,
        primaryPlanData?.indId,
        "WNBEN"
    );

    const { data: beneficiaryData } = useGetBeneficiaries(primaryPlanData?.gaId);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [selectedEmail, setSelectedEmail] = useState<string | null>(
        eDeliveryData?.econsentemailAddress
    );

    const [consentInd, setConsentInd] = useState<boolean>(eDeliveryData?.eDeliveryOption);

    const continueToMyAccount = useCallback(async () => {
        try {
            const { data } = await registrationSummaryDestination();
            redirect(data);
        } catch (_) {
            setShowErrorMessage(true);
        }
    }, [redirect]);

    const { mutate } = useUpdateParticipantConsent(continueToMyAccount, () =>
        setShowErrorMessage(true)
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const handleEDeliveryAction = useCallback(
        (
            _: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
            selectedValue: string | null,
            isEmailRequired: boolean
        ) => {
            setSelectedEmail(selectedValue);
            setConsentInd(isEmailRequired);
        },
        []
    );

    const allowEDelivery =
        eDeliveryData?.allowedToOverwrite === "true" && eDeliveryData?.statusCode == "A";

    const handleContinueClick = useCallback(() => {
        if (allowEDelivery && primaryPlanData) {
            mutate({
                email: selectedEmail,
                consentInd: consentInd,
                gaId: primaryPlanData.gaId,
                flowName: "registrationFlow"
            });
        } else {
            continueToMyAccount();
        }
    }, [allowEDelivery, consentInd, continueToMyAccount, mutate, primaryPlanData, selectedEmail]);

    if (summaryLoading || primaryPlanLoading) {
        return (
            <div className="loaderBackground">
                <div className="loader"></div>
            </div>
        );
    }

    if (!summaryData || !primaryPlanData || !txnAccessData || !beneficiaryData) {
        return null;
    }

    const beneficiaries = beneficiaryData.beneficiaries.reduce((prev, cur) => {
        if (cur.designationCode === "P") {
            prev.primary.push(cur);
        } else {
            prev.contingent.push(cur);
        }
        return prev;
    }, BENEFICIARIES_DEFAULT);

    const beneficiaryTypeMap = beneficiaryData.beneficiaryTypes.reduce((prev, cur) => {
        prev[cur.code] = cur.description;
        return prev;
    }, {});

    const { firstName, lastName, planName, investmentOptions, contributionRateList } = summaryData;

    const groupedInvestmentOptions: GroupedInvestmentOptionsType = investmentOptions.reduce(
        (prev, cur) => {
            if (!prev[cur.groupingCode]) {
                prev[cur.groupingCode] = [];
            }
            prev[cur.groupingCode].push(cur);
            return prev;
        },
        {}
    );

    const showBeneficiaries =
        txnAccessData.WNBEN === "true" &&
        beneficiaryTypeMap &&
        beneficiaries &&
        (beneficiaries.primary.length > 0 || beneficiaries.contingent.length > 0);

    return (
        <div className="confirmation container" data-testid="registration-summary">
            {showErrorMessage && <div className="has-error">{errorMessage}</div>}
            <div className="col-md-10 col-md-offset-1">
                <header className="contained-form-header">
                    <h1 className="header-title">{`${firstName} ${lastName}`}</h1>
                    <p>{summary.headline}</p>
                </header>

                <div className="inner-container with-shadow with-padding clearfix">
                    {planName && (
                        <>
                            <div className="row">
                                <div className="col-sm-10">
                                    <h3 className="margin-top-0">{summary.pageTitle}</h3>
                                </div>
                                <div className="col-sm-2">
                                    <button
                                        type="button"
                                        className="btn-link btn-print pull-right no-right-padding"
                                        onClick={handlePrint}
                                    >
                                        <span className="fa fa-solid fa-print margin-right-default"></span>
                                        {button.print}
                                    </button>
                                </div>
                            </div>
                            <div className="details-section">
                                <table className="table-details">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <strong>{summary.details.plan}</strong>
                                            </th>
                                            <td>{planName}</td>
                                        </tr>

                                        {contributionRateList &&
                                            contributionRateList.length > 0 && (
                                                <tr>
                                                    <th>
                                                        <strong>
                                                            {summary.details.contributionRate}
                                                        </strong>
                                                    </th>
                                                    <td>
                                                        {contributionRateList.map(
                                                            (contributionRate) => (
                                                                <div key={contributionRate}>
                                                                    {contributionRate}
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            )}

                                        {investmentOptions && investmentOptions.length > 0 && (
                                            <tr>
                                                <th>{summary.details.investmentOptions}</th>
                                                <td>
                                                    {groupedInvestmentOptions &&
                                                        Object.entries(
                                                            groupedInvestmentOptions
                                                        ).map(
                                                            ([groupingCode, investmentOptions]) => (
                                                                <div key={groupingCode}>
                                                                    {groupingCode && (
                                                                        <dt>{groupingCode}</dt>
                                                                    )}
                                                                    {investmentOptions.map(
                                                                        (investmentOption) => (
                                                                            <dd
                                                                                key={`${groupingCode}-${investmentOption.name}`}
                                                                            >
                                                                                {`${investmentOption.allocationPercentage}% ${investmentOption.name}`}
                                                                            </dd>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    {showBeneficiaries && (
                        <div className="beneficiaries">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h2> {summary.beneficiaries.title}</h2>
                                </div>
                            </div>

                            <div className="beneficiary-list">
                                {beneficiaries.primary.length > 0 && (
                                    <BeneficiaryTable
                                        nameHeading={app.beneficiaryTable.headings.primary}
                                        typeHeading={app.beneficiaryTable.headings.type}
                                        allocationHeading={app.beneficiaryTable.headings.allocation}
                                        beneficiaries={beneficiaries.primary}
                                        beneficiaryTypeMap={beneficiaryTypeMap}
                                    />
                                )}
                                {beneficiaries.contingent.length > 0 && (
                                    <BeneficiaryTable
                                        nameHeading={app.beneficiaryTable.headings.contingent}
                                        typeHeading={app.beneficiaryTable.headings.type}
                                        allocationHeading={app.beneficiaryTable.headings.allocation}
                                        beneficiaries={beneficiaries.contingent}
                                        beneficiaryTypeMap={beneficiaryTypeMap}
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {allowEDelivery && (
                        <EDeliveryInlineViewContainer
                            action={handleEDeliveryAction}
                            eDeliveryInitData={eDeliveryData}
                        />
                    )}

                    <hr className="dashed" />
                    <div className="row">
                        <div className="col-md-3 col-md-offset-9">
                            <button
                                data-testid="continue-button"
                                className="btn btn-primary pull-right"
                                onClick={handleContinueClick}
                            >
                                {button.agreeContinue}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationSummary;
