import React from "react";
import { useTranslations } from "../../queries";

interface RedwoodMfaTranslation {
    app: {
        common: {
            cancel: string;
        };
    };
    redwoodMfa: {
        callBtn: string;
        cancelBtn: string;
        redWoodMfaTitle: string;
        redwoodMfeDescription: string;
        textBtn: string;
        verificationDesc: string;
    };
}
const LandingRedwoodMfa = () => {
    const { redwoodMfa } = useTranslations<RedwoodMfaTranslation>();
    return (
        <div>
            <div
                className="container row redwood-mra-container"
                data-testid="id-proof-error-component"
            >
                <div className="row">
                    <div className="registration col-xs-6 col-xs-offset-5">
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active">
                                <div className="header-bg">
                                    <span className="site-logo-wrapper">
                                        <a href="#/login" className="remove-outline">
                                            <img
                                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                className="redwood-site-logo"
                                                alt="site logo"
                                            />
                                        </a>
                                    </span>
                                </div>
                                <div className="align-center">
                                    <h2 className="signin-text-color">
                                        {redwoodMfa.redWoodMfaTitle}
                                    </h2>
                                    <p>{redwoodMfa.redwoodMfeDescription}</p>
                                </div>
                                <div className="button-group">
                                    <button className="button-container">
                                        {redwoodMfa.textBtn}
                                    </button>
                                    <button className="button-container">
                                        {redwoodMfa.callBtn}
                                    </button>
                                </div>
                                <div className="cancel-btn-align">
                                    <button className="cancel-button">
                                        {redwoodMfa.cancelBtn}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingRedwoodMfa;
