import React from "react";

import RegistrationContext from "./container/RegistrationContext";
import { useTranslations } from "../../queries";

const Registration = () => {
    const translations = useTranslations();
    const continueButton = translations.button.continue;

    const showContinuation = () => {
        const fragment = (
            <React.Fragment>
                <span>{continueButton}</span>
                <span hidden={true}>
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    Processing ...
                </span>
            </React.Fragment>
        );
        return fragment;
    };

    const getNextPage = () => {
        // Is this supposed to do something?
        console.log("testing");
    };

    const getRegistration = () => {
        const fragment = (
            <RegistrationContext.Provider value={RegistrationContext}>
                <div className="form-group clearfix">
                    <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary btn-block btn-lg margin-top-default"
                        onClick={getNextPage()}
                    >
                        {showContinuation()}
                    </button>
                </div>
            </RegistrationContext.Provider>
        );
        return fragment;
    };

    return getRegistration();
};
export default Registration;
