import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { OAUTH_LOGIN, OAUTH_PRECONSENT, ACTIVATION_CODE_DELIVERY_OPTIONS } from "../../routes";

import DeliveryOptions from "../../components/mfa/deliveryOptions/DeliveryOptions"; //"./deliveryOptions/DeliveryOptions";
import OAuthContainer from "./container/OAuthContainer";
import Preconsent from "./preconsent/Preconsent";

/**
 * TODO: DELETE THIS COMPONENT WHEN ANGULAR IS REMOVED.
 * This is a temporary component that provides access to react router, react query, and the translations hook
 * while still allowing the new MFA components to be embedded in the Angular application.
 * Also temporarily pass the redirectService from the Angular app until that has been converted.
 */
const OAuth = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path={OAUTH_LOGIN} element={<OAuthContainer />}></Route>
                <Route path={OAUTH_PRECONSENT} element={<Preconsent />}></Route>
                <Route
                    path={ACTIVATION_CODE_DELIVERY_OPTIONS}
                    element={<DeliveryOptions />}
                ></Route>
            </Routes>
        </HashRouter>
    );
};

export default OAuth;
