import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { IDPROOF_OTP_VERIFY } from "../../../routes";

import IdentityVerificationComponent from "./IdentityVerificationComponent";

interface Props {
    $state: { go: (path: string) => void };
}

//TODO:This wrapper will remove once angular components completely migrated to react
const IdentityVerificationComponentWrapper = ({ $state }: Props) => {
    return (
        <HashRouter>
            <Routes>
                <Route
                    path={IDPROOF_OTP_VERIFY}
                    element={<IdentityVerificationComponent state={$state} />}
                ></Route>
            </Routes>
        </HashRouter>
    );
};

export default IdentityVerificationComponentWrapper;
