import type { AxiosResponse } from "axios";
import { executeGet } from "../../../utils/appUtils";
import StringUtil from "core-ui/client/src/app/core/util/StringUtil";
import ReferenceData from "../../../constants/ReferenceData";

interface GroupTxnAccessParams {
    gaId: string;
    indId: string;
    txnCode: string;
}

const groupTxnAccess = async (params: GroupTxnAccessParams): Promise<AxiosResponse> => {
    return executeGet(
        StringUtil.supplant(ReferenceData.GROUP_TXN_ACCESS, params),
        "groupTxnAccessFault"
    );
};

export default groupTxnAccess;
