import { skipToken, useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import groupTxnAccess from "../../services/common/groupTxnAccess";

const useGroupTxnAccess = (
    gaId: string | undefined,
    indId: string | undefined,
    txnCode: string
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GROUP_TXN_ACCESS, gaId, indId, txnCode],
        queryFn:
            gaId && indId && txnCode
                ? async () => {
                      const { data } = await groupTxnAccess({ gaId, indId, txnCode });
                      return data;
                  }
                : skipToken,
        enabled: Boolean(gaId && indId && txnCode)
    });
};

export default useGroupTxnAccess;
