import type { AxiosResponse } from "axios";
import { executeGet } from "../../../utils/appUtils";
import ReferenceData from "../../../constants/ReferenceData";

interface PrimaryPlanResponse {
    gaId: string;
    indId: string;
}

const primaryPlan = async (): Promise<AxiosResponse<PrimaryPlanResponse>> => {
    return executeGet(ReferenceData.GET_PRIMARY_PLAN, "primaryPlanFault");
};

export default primaryPlan;
