import React from "react";

interface Beneficiary {
    beneTypeCode: string;
    designationCode: "P" | "C";
    firstName: string;
    id: string;
    lastName: string;
    percent: number;
}

interface BeneficiaryTableProps {
    allocationHeading: string;
    beneficiaries: Beneficiary[];
    beneficiaryTypeMap: { [key: string]: string };
    nameHeading: string;
    typeHeading: string;
}

const BeneficiaryTable = ({
    nameHeading,
    typeHeading,
    allocationHeading,
    beneficiaries,
    beneficiaryTypeMap
}: BeneficiaryTableProps) => {
    return (
        <div className="beneficiaries-wrap">
            <table className="beneficiaries contingent-beneficiaries table">
                <thead>
                    <tr>
                        <th className="col-xs-5 col-sm-6">{nameHeading}</th>
                        <th className="col-xs-3 col-sm-4">{typeHeading}</th>
                        <th className="col-xs-4 col-sm-2">{allocationHeading}</th>
                    </tr>
                </thead>
                <tbody>
                    {beneficiaries.map((beneficiary) => (
                        <tr key={beneficiary.id}>
                            <td className="beneficiary-name">
                                {`${beneficiary.firstName} ${beneficiary.lastName}`}
                            </td>
                            <td className="beneficiary-type">
                                {beneficiaryTypeMap[beneficiary.beneTypeCode]}
                            </td>
                            <td className="beneficiary-allocation">{`${beneficiary.percent}%`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BeneficiaryTable;
