import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import { getOAuthConsentURL } from "../../services/oauth/OAuthService";

const useGetOAuthUrl = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_OAUTH_CONSENT_URL],
        queryFn: () => getOAuthConsentURL()
    });
};

export default useGetOAuthUrl;
