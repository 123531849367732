import React from "react";

import AccountRecoveryConfirmation from "../AccountRecoveryConfirmation";

const AccountRecoveryConfirmationContainer = () => {
    return (
        <React.StrictMode>
            <AccountRecoveryConfirmation />
        </React.StrictMode>
    );
};

AccountRecoveryConfirmationContainer.propTypes = {};
export default AccountRecoveryConfirmationContainer;
