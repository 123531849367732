import { useMutation } from "@tanstack/react-query";
import { updateParticipantConsent } from "core-ui/client/src/app/core/eDelivery/EDeliveryFactory";

interface UpdateParticipantConsentSuccess {
    status: string;
}

interface UpdateParticipantConsentError {
    code: string;
}

interface UpdateParticipantConsentParams {
    consentInd: boolean;
    email: string | null;
    flowName: string;
    gaId: string;
}

const useUpdateParticipantConsent = (
    onSuccess: (data: UpdateParticipantConsentSuccess) => void,
    onError: (error: UpdateParticipantConsentError) => void
) => {
    return useMutation<
        UpdateParticipantConsentSuccess,
        UpdateParticipantConsentError,
        UpdateParticipantConsentParams
    >({
        mutationFn: updateParticipantConsent,
        onSuccess,
        onError
    });
};

export default useUpdateParticipantConsent;
