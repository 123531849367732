import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import primaryPlan from "../../services/common/primaryPlan";

const usePrimaryPlan = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_PRIMARY_PLAN],
        queryFn: async () => {
            const { data } = await primaryPlan();
            return data;
        }
    });
};

export default usePrimaryPlan;
