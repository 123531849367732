import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import registrationSummary from "../../services/register/registrationSummary";

const useRegistrationSummary = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_REGISTRATION_SUMMARY],
        queryFn: async () => {
            const { data } = await registrationSummary();
            return data;
        }
    });
};

export default useRegistrationSummary;
