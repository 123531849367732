import type { AxiosResponse } from "axios";
import ReferenceData from "../../../constants/ReferenceData";
import { executeGet } from "../../../utils/appUtils";

interface GetBeneficiariesResponse {
    beneficiaries: {
        beneTypeCode: string;
        designationCode: "P" | "C";
        firstName: string;
        id: string;
        lastName: string;
        percent: number;
    }[];
    beneficiaryTypes: { code: string; description: string }[];
}

const getBeneficiaries = (gaId: string): Promise<AxiosResponse<GetBeneficiariesResponse>> => {
    return executeGet(`${ReferenceData.GET_BENEFICIARIES}/${gaId}`, "getBeneficiariesFault");
};

export default getBeneficiaries;
