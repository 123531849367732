import { datadogRum } from "@datadog/browser-rum";
import { shouldUseSentryAnalytics } from "../sentryMetricsLogging";

type AuthenticationType = "NONE" | "SSO" | "DF" | "MFA";

/**
 * Sets the DataDog user property for the given authentication type if DataDog is enabled.
 *
 * @param {AuthenticationType} authenticationType - The type of authentication to set as a user property in DataDog.
 * @returns {null} Always returns null.
 */
const setDataDogUserProperty = (authenticationType: AuthenticationType): null => {
    const isDataDogEnabled = !shouldUseSentryAnalytics();

    if (isDataDogEnabled) {
        // set the data dog user context for authenticationType
        datadogRum.setUserProperty("authenticationType", authenticationType);
    }

    return null;
};

export default setDataDogUserProperty;
