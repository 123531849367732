import ReferenceData from "../../constants/ReferenceData";
import { executeGetWithAuth, executePost } from "../../utils/appUtils";

import axios, { AxiosError } from "axios";
import { get as _get } from "lodash";
import { handleFault, FAIL } from "../../utils/errorUtils";

export const getOAuthLoginUrl = () => {
    const getOAuthLoginUrlSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    let liveUrl = __baseApiUrl__ + ReferenceData.GET_OAUTH_LOGIN_URL;
    const urlParams = new URLSearchParams(window.location.href);
    const login_challenge = urlParams.get("login_challenge");

    // "http://localhost:8080/participant-web-services-PROJ_11-SNAPSHOT/rest/nonauth/oauth2/loginCustomization/29fa77f338b54e96adb1857e9471e11c",
    if (login_challenge) {
        liveUrl += "/" + login_challenge;
    }

    const params = {
        flowName: "OAUTH_FLOW"
    };

    return executeGetWithAuth(liveUrl, "getOAuthLoginUrlFault", params)
        .then(getOAuthLoginUrlSuccess)
        .catch((err) => {
            console.log("getOAuthLoginUrl Error: ", err);
            throw err;
        });
};

/**
 * Retrieve consent url
 * @returns
 */
export const getOAuthConsentURL = () => {
    const getOAuthConsentURLSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    let liveUrl = __baseApiUrl__ + ReferenceData.GET_OAUTH_CONSENT_URL;
    const urlParams = new URLSearchParams(window.location.href);
    const consent_challenge = urlParams.get("consent_challenge");

    //"http://localhost:8080/participant-web-services-PROJ_11-SNAPSHOT/rest/nonauth/oauth2/loginCustomization/getConsentRequest/a4df27a363274a23b0be149dd6b1400a"
    if (consent_challenge) {
        liveUrl += "/" + consent_challenge;
    }

    const params = {
        flowName: "OAUTH_FLOW"
    };

    return executeGetWithAuth(liveUrl, "getOAuthConsentURLFault", params)
        .then(getOAuthConsentURLSuccess)
        .catch((err) => {
            console.log("getOAuthConsentURL Error: ", err);
            throw err;
        });
};

/**
 * Reject Oauth Consent
 * @param {*} consent_challenge
 * @returns
 */
export const rejectOauthConsent = (consent_challenge) => {
    const rejectOauthConsentSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    let liveUrl = __baseApiUrl__ + ReferenceData.REJECT_OAUTH_CONSENT;
    if (consent_challenge) {
        liveUrl += "/" + consent_challenge;
    }

    const consentChallenge = {
        challenge: consent_challenge
    };

    const params = {
        flowName: "OAUTH_FLOW"
    };

    return executePost(liveUrl, "rejectOauthConsentFault", consentChallenge, params)
        .then(rejectOauthConsentSuccess)
        .catch((err) => {
            console.log("rejectOauthConsent Error: ", err);
            throw err;
        });
};

/**
 * Accept Consent
 * @param {*} consentData
 * @returns
 */
export const acceptOauthConsent = (consentData) => {
    const acceptOauthConsentSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.ACCEPT_OAUTH_CONSENT;
    const options = {
        flowName: "OAUTH_FLOW"
    };

    return executePost(liveUrl, "acceptOauthConsentFault", consentData, options)
        .then(acceptOauthConsentSuccess)
        .catch((err) => {
            console.log("acceptOauthConsent Error: ", err);
            throw err;
        });
};

const executeGetCache = {};

const checkResponseForError = (response) => {
    if (!_get(response, "data") || _get(response, "data.status") === FAIL) {
        throw response;
    }
};

export const executeGetTokenWithAuth = async (url, faultLabel, params) => {
    if (executeGetCache[url]) {
        return executeGetCache[url];
    }
    try {
        const response = await axios.get(url, {
            ...params,
            withCredentials: true,

            headers: {
                Authorization: undefined
            },
            validateStatus: () => true
        });
        if (response.status === 302) {
            console.log("chk response 302", response);
            return response;
        }

        checkResponseForError(response);
        executeGetCache[url] = response;
        return response;
    } catch (err) {
        console.log("get auth error", err);
        if (err instanceof AxiosError) {
            console.log("catch err response ", err.response);
            throw handleFault(err.response, faultLabel);
        }

        throw handleFault(err.response, faultLabel);
    }
};
