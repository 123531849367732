import { skipToken, useQuery } from "@tanstack/react-query";
import { retrieveParticipantConsent } from "core-ui/client/src/app/core/eDelivery/EDeliveryFactory";
import { QUERY_KEYS } from "../constants";

const useParticipantConsent = (gaId: string | undefined) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PARTICIPANT_CONSENT, gaId],
        queryFn: gaId
            ? async () => {
                  const { data } = await retrieveParticipantConsent(gaId);
                  return data;
              }
            : skipToken,
        enabled: Boolean(gaId)
    });
};

export default useParticipantConsent;
