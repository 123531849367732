import React, { MouseEvent, useCallback } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "../../../queries";
import LoginHelpEvents from "./LoginHelpEvents";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";

import eventBus from "../../../../utils/setEventBus";
import { LOGIN_HELP_NO_PASSWORD } from "../../../routes";

interface LoginHelpNoPasswordProps {
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}
interface LoginHelpOptionsTranslations {
    buttonContinue: string;
    emailAddrTitle: string;
    emailFormat: string;
    emailLinkToResetPW: string;
    emailRequired: string;
    error: {
        errorOccurred: string;
    };
    forgotPasswordTitle: string;
}

const emailId = "email";

const NoPassword = ({ location, scope }: LoginHelpNoPasswordProps) => {
    const {
        buttonContinue,
        emailAddrTitle,
        emailFormat,
        emailLinkToResetPW,
        emailRequired,
        error,
        forgotPasswordTitle
    } = useTranslations<LoginHelpOptionsTranslations>();

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        register,
        trigger,
        formState: { errors, isSubmitting, isValid }
    } = useForm();

    const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9\-'`]+[.])+[a-zA-Z]{2,}$/;

    const updateAngularRoutingState = useCallback(() => {
        /**
         * TODO: Delete this function when Angular is removed. Since we are using the React router
         * we need to update the Angular routing state to match the new React routing state.
         */
        if (location && scope) {
            //   location.path(LOGIN_HELP_NO_PASSWORD);
            scope.$apply();
        }
    }, [location, scope]);

    const onSubmit = useCallback(
        async (formData) => {
            const { email } = formData;

            const emailFound = true;

            // TBD call service to check if email exists

            if (emailFound) {
                try {
                    navigate("/" + LOGIN_HELP_NO_PASSWORD, { replace: true });
                    updateAngularRoutingState();
                } catch (error) {
                    control.setError("root", { message: email + "error: " + String(error) });
                }
            } else {
                // TBD - add logic for 'Let's try something else' navigation
                control.setError("root", { message: error.errorOccurred });
            }

            const payload = emailId;
            const formType: (typeof AMPLITUDE_EVENTS)[keyof typeof AMPLITUDE_EVENTS] =
                AMPLITUDE_EVENTS.SELECT_BUTTON;

            eventBus.dispatch(formType, {}, payload);

            dispatchAmplitude({
                eventType: formType,
                selection: LoginHelpEvents.CLICK,
                payload: {
                    payload
                }
            });
        },
        [control, navigate, updateAngularRoutingState, error?.errorOccurred]
    );

    const handleInvalid = useCallback(() => {
        control.setError("root", {
            message: emailFormat
        });
    }, [control, emailFormat]);

    const triggerValidation = (e) => {
        trigger(e.target.name);
        dispatchAmplitudeEvent(e);
    };

    /**
     * Dispatches an amplitude event when a user interacts with the form input textbox.
     */
    const dispatchAmplitudeEvent = useCallback((event: MouseEvent) => {
        const payload = String(event.currentTarget.id).toLowerCase();
        const formType: (typeof AMPLITUDE_EVENTS)[keyof typeof AMPLITUDE_EVENTS] =
            AMPLITUDE_EVENTS.SELECT_FIELD;

        eventBus.dispatch(formType, event.target, payload);

        dispatchAmplitude({
            eventType: formType,
            selection: LoginHelpEvents.CLICK,
            payload: {
                payload
            }
        });
    }, []);

    return (
        <form
            autoComplete="off"
            noValidate
            name="noPasswordForm"
            data-testid="loginhelp-no-password"
            className="loginhelp-container"
            onSubmit={handleSubmit(onSubmit, handleInvalid)}
        >
            {isSubmitting && (
                <div className="loaderBackground">
                    <div className="loader"></div>
                </div>
            )}

            <h2>{forgotPasswordTitle}</h2>
            <div className="description">{emailLinkToResetPW}</div>
            <div className="description bold">{emailAddrTitle}</div>

            <div className="row-item-no-password" key={emailId}>
                <div
                    className={`form-group  ${errors.email && "has-error"}`}
                    data-testid={`controlled-form-field-${emailId}`}
                >
                    <label className="control-label" htmlFor={emailId}>
                        {""}
                    </label>
                    <input
                        className="text-container form-control"
                        data-testid={`controlled-form-field-input-${emailId}`}
                        id={emailId}
                        aria-label={emailId}
                        type={"text"}
                        {...register("email", {
                            required: emailRequired,
                            maxLength: {
                                value: 80,
                                message: emailFormat
                            },
                            pattern: {
                                value: pattern,
                                message: emailFormat
                            },
                            onChange: triggerValidation
                        })}
                    />

                    {errors.email && errors?.email?.message && (
                        <p className="error-block" aria-live="polite">
                            {String(errors.email.message)}
                        </p>
                    )}
                </div>
            </div>

            <div className="button-container">
                <button
                    id="continue"
                    className="btn btn-primary"
                    type="submit"
                    data-selection={LoginHelpEvents.CTA_BUTTON}
                    disabled={!isValid}
                >
                    {buttonContinue}
                </button>
            </div>
        </form>
    );
};

export default NoPassword;
