/**
 * @description get and determine if we should use sentry
 * @returns {Boolean} useSentry
 */
const shouldUseSentryAnalytics = (): boolean => {
    let useSentry = sessionStorage.getItem("useSentry");

    if (useSentry === null) {
        useSentry = String(Math.random() < 0.5);

        sessionStorage.setItem("useSentry", useSentry);
    }

    return useSentry === "true";
};

export default shouldUseSentryAnalytics;
