import "./utils/setJquery";
import "./utils/setWindowVariables";
import "./utils/setGlobalThisVariables";
import "./utils/setLogLevel";
import eventBus from "./utils/setEventBus";
import "core-ui/client/src/app/core/util/googleAnalytics/bootstrapGoogleAnalytics";
import { setForceSentryDebug } from "core-ui/client/react/core/utils/setSentryDebug";
import "./Application";

import React from "react";
import * as Sentry from "@sentry/react";
import {
    userLanguagePreference,
    pageLanguageSetting
} from "core-ui/client/react/core/utils/browserLocaleUtils";
import { getEnvironmentDisplayName } from "core-ui/client/react/core/utils/getEnvironmentDisplayName";
import { Provider } from "jotai";
import { createRoot } from "react-dom/client";

import formatTimingPrefix from "./utils/sentryMetricsLogging/formatTimingPrefix";
import { ApplicationContainer } from "./login/components/ApplicationContainer";
import { sentryLoginAppStartMetrics, shouldUseSentryAnalytics } from "./utils/sentryMetricsLogging";
import { datadogRum } from "@datadog/browser-rum";
import setDataDogUserProperty from "./utils/dataDog";

const url = window.location.origin.toLocaleLowerCase();
const isLocalhost = url.indexOf("127.0.0.1") > -1 || url.indexOf("localhost") > -1;

eventBus.amplitudeInit({});

const envDisplayName = getEnvironmentDisplayName(window.location.host);
const version = "login-ui@" + __applicationVersion__;

const initDataDog = () => {
    datadogRum.init({
        // Specify a version number to identify the deployed version of your application in Datadog
        version,
        applicationId: "97878329-65ef-4d66-97e5-4c4b5118b907", // set as env var
        clientToken: "pubcd5d6aa413e6fa3b236e55913db6156c", // set as env var
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "datadoghq.com",
        service: "wcdx---affiliate",
        env: envDisplayName,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100, // should we disable session replay?
        trackInteractions: true,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask" // Ensures data is masked unless explicitly set
    });

    // set the data dog user context for authenticationType
    setDataDogUserProperty("NONE");
};

const initSentry = () => {
    let sampleRate = window?.sentryConfig?.sampleRate;
    let tracesSampleRate = window?.sentryConfig?.tracesSampleRate;

    if (envDisplayName !== "Production" && window.sentryConfig.lowerEnvironments) {
        sampleRate = window?.sentryConfig?.lowerEnvironments?.sampleRate;
        tracesSampleRate = window?.sentryConfig?.lowerEnvironments?.tracesSampleRate;
    }
    // override sample rate if forceSentryDebug is set to true
    setForceSentryDebug();
    const forceSentryDebug = localStorage.getItem("forceSentryDebug");
    if (forceSentryDebug === "true") {
        sampleRate = 1;
        tracesSampleRate = 1;
    }

    Sentry.init({
        dsn: "https://fa5197db62314320a0e82ed740410868@o249255.ingest.us.sentry.io/4505086927306752",
        integrations: [
            Sentry.browserTracingIntegration({
                enableInp: true
            })
        ],
        release: version,
        environment: envDisplayName,
        sampleRate: sampleRate,
        tracesSampleRate: tracesSampleRate
    });
    Sentry.setTag("location_hash", window.location.hash);

    Sentry.setTag("user_locale", userLanguagePreference());
    Sentry.setTag("page_locale", pageLanguageSetting());

    window.addEventListener("hashchange", () => {
        Sentry.setTag("location_hash", window.location.hash);
    });
};

if (!isLocalhost) {
    const useSentry = shouldUseSentryAnalytics();

    if (useSentry) {
        initSentry();
    } else {
        initDataDog();
    }

    const timingPrefix = formatTimingPrefix();
    const LOGIN_LAST_HASH = "loginLastHash";
    const eventName = `${timingPrefix}${LOGIN_LAST_HASH}`;

    // set initial hash
    sessionStorage.setItem(eventName, window.location.hash);

    window.addEventListener("hashchange", () => {
        sessionStorage.setItem(eventName, window.location.hash);
    });
}

const getBackgroundImage = function () {
    let className = "";

    const path = String(window.location.hash).replace("#", "");
    switch (path) {
        case "/accountOpenDetails":
            className = "site-wrapper-ots";
            break;
        case "/accountOpenPersonalDetails":
            className = "site-wrapper-ots";
            break;
        default:
            className = "site-wrapper";
            break;
    }
    return className;
};

const loadReactApp = () => {
    //useCallback(
    const appContainer = document.getElementById("new-app-container");
    const backGroundClass = getBackgroundImage();
    appContainer.classList.add(backGroundClass);

    const root = createRoot(appContainer);

    sentryLoginAppStartMetrics();

    root.render(
        <Provider>
            <ApplicationContainer />
        </Provider>
    );
};

const isReactUrl = String(window.location.href).indexOf("isReact=true") > -1;

if (isReactUrl) {
    const item = document.getElementById("angularApp");
    if (item) {
        item.style.display = "none";
        item.style.visibility = "hidden";
    }
    loadReactApp();
}
