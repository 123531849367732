import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ACCOUNT_OPEN_DETAILS } from "../../routes";

import AccountOpenDetailsForm from "./AccountOpenDetailsForm";

//TODO:This wrapper will remove once angular components completely migrated to react
interface MFAProps {
    $state: { go: (path: string) => void };
}
const AccountOpenDetailsFormWrapper = ({ $state }: MFAProps) => {
    return (
        <HashRouter>
            <Routes>
                <Route
                    path={ACCOUNT_OPEN_DETAILS}
                    element={<AccountOpenDetailsForm hasReferrerParam state={$state} />}
                ></Route>
            </Routes>
        </HashRouter>
    );
};

export default AccountOpenDetailsFormWrapper;
